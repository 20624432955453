import logo from './img/TahmKench_Crewmate.png';
import './App.css';
import Contador from './modules/boton';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          ¡Bienvenidos a artilugio web studio app etc V2 Hiper Renovada!
        </p>
        <p> <b>Hola</b> <i>mundo!</i></p>
        <a
          className="App-link"
          href="http://smitesmalos.com.ar"
          target="_blank"
          rel="noopener noreferrer"
          >
          La pagina de Drake...
        </a>
        <Contador />
      </header>
    </div>
  );
}

export default App;
